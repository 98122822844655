import {
  Container,
  ContainerProps,
  Heading,
  HeadingProps,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import React from 'react';

export type HeadlineType = {
  headline: string;
};

type Props = {
  children: React.ReactNode;
};

const Headline = (props: Props) => {
  return (
    <Container {...container}>
      <Heading {...headline}>{props.children}</Heading>
    </Container>
  );
};

const headline: HeadingProps = {
  fontSize: { base: 'xl', md: '2xl' },
  lineHeight: 'shorter',
};
const container: ContainerProps = {
  marginTop: { base: '100px', md: '120px' },
  marginBottom: { base: '80px', md: '120px' },
};

export default Headline;
