import React, { useEffect, useState } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Show,
  useMediaQuery,
} from '@chakra-ui/react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import Arrow from './icons/arrow';

export type SlideshowType = {
  slides: {
    gatsbyImage: IGatsbyImageData;
    altText: string;
  }[];
  initFlag: boolean;
};

const Slideshow = (props: SlideshowType) => {
  const [isDesktop] = useMediaQuery('(min-width: 768px)');

  const slides = props.slides || [];
  if (props.initFlag) {
    return (
      <Box {...wrapperProps}>
        <Container {...containerProps}>
          <Swiper
            style={{ width: '100%', overflow: 'visible' }}
            slidesPerView={'auto'}
            modules={[Pagination]}
            pagination={isDesktop ? false : true}
            spaceBetween={0}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 8,
              },
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <Box {...slideProps}>
                  <Show above="md">
                    <GatsbyImage
                      alt={slide.altText || ''}
                      image={slide.gatsbyImage}
                    />
                  </Show>
                  <Show below="md">
                    <GatsbyImage
                      alt={slide.altText || ''}
                      image={slide.gatsbyImage}
                      style={{
                        height: 'calc(100vw - 2rem)',
                      }}
                    />
                  </Show>
                </Box>
              </SwiperSlide>
            ))}
            <Show above="md">
              <Box {...navigationContainerProps}>
                <SlidePrevButton />
                <SlideNextButton />
              </Box>
            </Show>
          </Swiper>
        </Container>
      </Box>
    );
  }
  return <></>;
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  const [isEnd, setIsEnd] = useState(swiper.isEnd);
  useEffect(() => {
    swiper.on('slideChange', () => {
      setIsEnd(swiper.isEnd);
    });
  }, []);
  if (isEnd) return null;
  return (
    <Box {...buttonNextPosition}>
      <Button
        {...buttonNext}
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <Arrow />
      </Button>
    </Box>
  );
};

const SlidePrevButton = () => {
  const swiper = useSwiper();
  const [isBeginning, setIsBeginning] = useState(swiper.isBeginning);
  useEffect(() => {
    swiper.on('slideChange', () => {
      setIsBeginning(swiper.isBeginning);
    });
  }, []);
  if (isBeginning) return null;
  return (
    <Box {...buttonPrevPosition}>
      <Button
        {...buttonPrev}
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <Arrow />
      </Button>
    </Box>
  );
};
const buttonPrevPosition: BoxProps = {
  position: 'absolute',
  left: '-6px',
};
const buttonNextPosition: BoxProps = {
  position: 'absolute',
  right: '-6px',
};

const buttonPrev: ButtonProps = {
  variant: 'slideshowNavigation',
  role: 'group',
  transform: 'rotate(180deg)',
};
const buttonNext: ButtonProps = {
  variant: 'slideshowNavigation',
  role: 'group',
};
const navigationContainerProps: BoxProps = {
  position: 'absolute',
  zIndex: 50,
  marginTop: '-20px',
  top: '50%',
  left: { md: '2%', xl: '0%' },
  width: { md: '96%', xl: '100%' },
};

const wrapperProps: BoxProps = {
  paddingTop: { base: '100px', md: '120px' },
  paddingBottom: { base: '120px', md: '120px' },
  background: 'black',
  overflow: 'hidden',
};

const containerProps: ContainerProps = {
  position: 'relative',
  padding: '0',
  sx: {
    '.swiper-pagination': {
      marginX: '8px !important',
      bottom: '-32px !important',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.swiper-pagination-bullet': {
      background: 'white',
      width: '8px',
      height: '8px',
      opacity: 1,
      marginX: '4px !important',
    },
    '.swiper-pagination-bullet-active': {
      background: 'white',
      width: '12px',
      height: '12px',
    },
  },
};

const slideProps: BoxProps = {
  borderRadius: { base: '10px', md: '20px' },
  transform: 'translate3d(0,0,0)',
  overflow: 'hidden',
  marginX: { base: '1em' },
};

export default Slideshow;
