import { graphql } from 'gatsby';
import React, { useLayoutEffect, useState } from 'react';
import Layout from '../components/layout';
import Headline, { HeadlineType } from '../components/headline';
import HeroSlideshow, { HeroSlide } from '../components/heroSlideshow';
import Slideshow, { SlideshowType } from '../components/slideshow';
import TextImage, { TextImageType } from '../components/textImage';
import Seo from '../components/seo';

type ContentType =
  | (HeadlineType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_Headline';
    })
  | (SlideshowType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_Slideshow';
    })
  | (TextImageType & {
      contentType: 'Template_Frontpage_Frontpagedata_Content_TextImage';
    });

type Props = {
  data: {
    pageData: {
      template: {
        frontpageData: {
          heroSlideshow: HeroSlide[];
          content: ContentType[];
        };
      };
    };
    seoData: {
      seo: {
        metaDescription: string;
        metaKeywords: string;
      };
    };
  };
};

const Frontpage = ({ data: { pageData, seoData } }: Props) => {
  const heroSlides = pageData?.template?.frontpageData?.heroSlideshow || [];
  const content = pageData?.template?.frontpageData?.content || [];
  let textToggleCount = 0;
  const [initFlag, setInitFlag] = useState(false);

  useLayoutEffect(() => {
    setInitFlag(true);
  }, []);

  return (
    <Layout>
      <Seo {...seoData.seo} />
      <HeroSlideshow slides={heroSlides} />
      {content.map((item, index) => {
        if (
          item.contentType ===
          'Template_Frontpage_Frontpagedata_Content_Headline'
        ) {
          return <Headline key={index}>{item.headline}</Headline>;
        }
        if (
          item.contentType ===
          'Template_Frontpage_Frontpagedata_Content_Slideshow'
        ) {
          return (
            <Slideshow key={index} slides={item.slides} initFlag={initFlag} />
          );
        }
        if (
          item.contentType ===
          'Template_Frontpage_Frontpagedata_Content_TextImage'
        ) {
          return (
            <TextImage
              key={index}
              {...item}
              imagePosition={textToggleCount++ % 2 ? 'left' : 'right'}
              initFlag={initFlag}
            />
          );
        }
        return null;
      })}
    </Layout>
  );
};

export default Frontpage;

export const pageQuery = graphql`
  query FrontpageQuery($id: String!) {
    pageData: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Frontpage {
          frontpageData {
            heroSlideshow {
              ... on WpTemplate_Frontpage_Frontpagedata_HeroSlideshow_Slide {
                headline
                text
                image {
                  gatsbyImage(width: 1440, layout: FULL_WIDTH, fit: COVER)
                }
              }
            }
            content {
              ... on WpTemplate_Frontpage_Frontpagedata_Content_Headline {
                contentType: fieldGroupName
                headline
              }
              ... on WpTemplate_Frontpage_Frontpagedata_Content_Slideshow {
                contentType: fieldGroupName
                slides {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                  altText
                }
              }
              ... on WpTemplate_Frontpage_Frontpagedata_Content_TextImage {
                contentType: fieldGroupName
                variant
                headline
                showHxiLogo
                text
                button {
                  title
                  url
                  target
                }
                image {
                  gatsbyImage(width: 620, layout: FULL_WIDTH, aspectRatio: 1.5)
                  altText
                }
              }
            }
          }
        }
      }
    }
    seoData: wpPage(id: { eq: $id }) {
      seo {
        metaDescription
        metaKeywords
      }
    }
  }
`;
