import {
  Box,
  BoxProps,
  Button,
  Container,
  Heading,
  SimpleGrid,
  SimpleGridProps,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReachLink } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { WpLinkType } from '../types/WpLinkType';
import HxiLogo from '../images/logo-hxi.svg';

type ButtonVariant = 'whiteToBlack' | 'whiteToOrange' | 'blackToOrange';

export type TextImageType = {
  variant: 'white' | 'green' | 'orange';
  imagePosition: 'left' | 'right';
  headline?: string;
  showHxiLogo?: boolean;
  text?: string;
  button?: WpLinkType;
  image?: {
    altText: string;
    gatsbyImage: IGatsbyImageData;
  };
  initFlag: boolean;
};

const TextImage = (props: TextImageType) => {
  const variantProps: BoxProps = {};
  let buttonVariant: ButtonVariant = 'whiteToBlack';
  if (props.variant === 'white') {
    buttonVariant = 'blackToOrange';
    variantProps.color = 'black';
    variantProps.background = 'white';
    variantProps.marginTop = { base: '80px', md: '120px' };
    variantProps.marginBottom = { base: '80px', md: '120px' };
  } else if (props.variant === 'green') {
    variantProps.color = 'white';
    variantProps.background = 'brand.green';
    variantProps.paddingTop = { base: '80px', md: '120px' };
    variantProps.paddingBottom = { base: '80px', md: '120px' };
  } else if (props.variant === 'orange') {
    variantProps.color = 'white';
    variantProps.background = 'brand.orange';
    variantProps.paddingTop = { base: '80px', md: '120px' };
    variantProps.paddingBottom = { base: '80px', md: '120px' };
  }

  return (
    <Box {...variantProps}>
      <Container>
        <SimpleGrid {...gridProps}>
          {props.image && (
            <Box
              display={{ base: 'none', md: 'block' }}
              gridColumn={
                props.imagePosition === 'left' ? '1 / span 6' : '7  / span 6'
              }
              gridRow={{ md: '1' }}
            >
              <Box
                borderRadius="20px"
                transform="translate3d(0,0,0)"
                overflow="hidden"
              >
                <GatsbyImage
                  alt={props.headline || ''}
                  image={props.image.gatsbyImage}
                />
              </Box>
            </Box>
          )}
          <Box
            gridColumn={{
              lg: props.imagePosition === 'left' ? '7 / span 5' : '2 / span 5',
              md: props.imagePosition === 'left' ? '7 / span 6' : '1 / span 6',
            }}
            gridRow={{ md: '1' }}
            marginTop={{ md: '30px' }}
          >
            {props.headline && (
              <Heading as="h3" {...headlineProps}>
                {props.headline}
                {props.showHxiLogo && (
                  <img
                    src={HxiLogo}
                    style={{ display: 'inline-block', marginLeft: '1rem' }}
                    alt="hxi.digital"
                  />
                )}
              </Heading>
            )}
            {props.image && (
              <Box
                display={{ md: 'none' }}
                borderRadius="10px"
                transform="translate3d(0,0,0)"
                overflow="hidden"
                marginBottom="20px"
              >
                <GatsbyImage
                  alt={props.headline || ''}
                  image={props.image.gatsbyImage}
                  style={{ height: 'calc(100vw - 2rem)' }}
                />
              </Box>
            )}
            {props.text && (
              <Box
                {...textProps}
                dangerouslySetInnerHTML={{ __html: props.text }}
              />
            )}
            {props.button && (
              <>
                {props.button.url.slice(0, 4) === 'http' ? (
                  <Button
                    as="a"
                    href={props.button.url}
                    target={props.button.target}
                    variant={buttonVariant}
                    marginTop="24px"
                  >
                    {props.button.title}
                  </Button>
                ) : (
                  <Button
                    as={ReachLink}
                    to={props.button.url}
                    target={props.button.target}
                    variant={buttonVariant}
                    marginTop="24px"
                  >
                    {props.button.title}
                  </Button>
                )}
              </>
            )}
          </Box>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const gridProps: SimpleGridProps = {
  columns: { md: 12 },
  columnGap: '40px',
};

const headlineProps: BoxProps = {
  fontSize: '48px',
  lineHeight: 'short',
  marginBottom: '8px',
};

const textProps: BoxProps = {
  fontSize: { base: 'sm', md: 'md' },
  lineHeight: 'base',
};

export default TextImage;
