import React from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Show,
  SimpleGrid,
} from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Arrow from './icons/arrow';
import arrowDown from '../images/arrowDown.svg';

export type HeroSlide = {
  headline: string;
  text: string;
  image: {
    gatsbyImage: IGatsbyImageData;
  };
};

type Props = {
  slides: HeroSlide[];
};

const HeroSlideshow = (props: Props) => {
  const slides = props.slides || [];
  const scrollDown = () => {
    window.scroll({
      top: window.innerHeight - 64,
      behavior: 'smooth',
    });
  };
  return (
    <Box {...wrapperProps}>
      <Swiper
        modules={[Pagination, EffectFade]}
        pagination={{ clickable: true }}
        effect="fade"
        style={{
          width: '100%',
          height: 'calc(100vh - 64px)',
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box {...swiperContainer}>
              <GatsbyImage
                image={slide.image.gatsbyImage}
                alt=""
                objectFit="cover"
              />
              <Box {...textPosition}>
                <Container position="relative">
                  <Heading {...headlineProps}>{slide.headline}</Heading>
                  <Box {...textProps}>{slide.text}</Box>
                  <Show above="md">
                    <Button {...scrollDownProps} onClick={scrollDown}>
                      {/* <Arrow color="#FFF" /> */}
                      <img
                        src={arrowDown}
                        style={{ pointerEvents: 'none' }}
                        alt=""
                        width={29}
                        height={45}
                      />
                    </Button>
                  </Show>
                </Container>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

const scrollDownProps: ButtonProps = {
  variant: 'unstyled',
  role: 'group',
  position: 'absolute',
  bottom: '-48px',
  height: '45px',
  width: '45px',
  right: { md: '0px', xl: '-10px' },
  //right: { md: '0', xl: '-12px' },
  //bottom: '-64px',
  //width: '64px',
  //height: '64px',
  //transform: 'rotate(90deg)',
  //transformOrigin: 'center',
  _focus: {
    outline: 'none',
  },
};

const wrapperProps: BoxProps = {
  position: 'relative',
  sx: {
    '.swiper-pagination': {
      textAlign: { base: 'left', md: 'center' },
      marginX: '8px !important',
      bottom: { base: '28px !important', md: '20px !important' },
    },
    '.swiper-pagination-bullet': {
      background: 'white',
      width: '16px',
      height: '16px',
      opacity: 1,
      marginX: '8px !important',
    },
    '.swiper-pagination-bullet-active': {
      background: 'brand.orange',
    },
  },
};

const swiperContainer: BoxProps = {
  position: 'relative',
  height: '100%',
  background: 'black',
  sx: {
    '.gatsby-image-wrapper': {
      height: '100%',
      opacity: '0.65',
    },
  },
};

const textPosition: BoxProps = {
  position: 'absolute',
  bottom: '80px',
  width: '100%',
};

const headlineProps: HeadingProps = {
  fontSize: { base: '48px', md: '80px' },
  lineHeight: 'shorter',
  color: 'white',
  maxWidth: '1024px',
  marginBottom: '8px',
};
const textProps: BoxProps = {
  maxWidth: '420px',
  fontSize: { base: 'sm', md: 'md' },
  color: 'white',
  marginLeft: { base: '2px', md: '4px' },
};

export default HeroSlideshow;
